import {ComponentProps, forwardRef} from 'react';
import styled, {useTheme} from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  grid-area: ${(props) => (props.style?.gridArea ? props.style?.gridArea : '')};
`;
const SearchBarIcon = styled.div`
  position: absolute;
  height: 50%;
  overflow: hidden;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
`;

const Input = styled.input`
  border: none;
  border-radius: 10px;
  height: 44px;
  display: block;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.3125;
  font-weight: 500;
  color: ${(props) => props.theme.colors.neutral100};
  background: ${(props) => props.theme.colors.primary500};
  text-align: center;
  &:focus {
    outline: none;
  }
  &:valid {
    background: ${(props) => props.theme.colors.secondary400};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.neutral100};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${(props) => props.theme.colors.neutral100};
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.neutral100};
    opacity: 1;
  }
`;

interface FilterInputProps extends ComponentProps<typeof Input> {
  placeHolder?: string;
  type?: string;
  isShadow?: boolean;
}

export const CustomSearchBar = forwardRef(
  ({placeHolder, type, isShadow, ...props}: FilterInputProps, ref) => {
    const theme = useTheme();
    return (
      <Container {...props}>
        <Input
          {...props}
          style={{
            boxShadow: `${isShadow ? theme.shadow.shadow1 : 'unset'}`,
            ...props.style,
          }}
          required={true}
          placeholder={placeHolder}
          type={type || 'text'}
          ref={ref}
        />
        <SearchBarIcon>
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 18.25L12 12.75M1 8.16667C1 9.00932 1.16597 9.84371 1.48844 10.6222C1.81091 11.4007 2.28356 12.1081 2.8794 12.7039C3.47524 13.2998 4.18261 13.7724 4.96111 14.0949C5.73962 14.4174 6.57402 14.5833 7.41667 14.5833C8.25932 14.5833 9.09371 14.4174 9.87222 14.0949C10.6507 13.7724 11.3581 13.2998 11.9539 12.7039C12.5498 12.1081 13.0224 11.4007 13.3449 10.6222C13.6674 9.84371 13.8333 9.00932 13.8333 8.16667C13.8333 7.32402 13.6674 6.48962 13.3449 5.71111C13.0224 4.93261 12.5498 4.22524 11.9539 3.6294C11.3581 3.03356 10.6507 2.56091 9.87222 2.23844C9.09371 1.91597 8.25932 1.75 7.41667 1.75C6.57402 1.75 5.73962 1.91597 4.96111 2.23844C4.18261 2.56091 3.47524 3.03356 2.8794 3.6294C2.28356 4.22524 1.81091 4.93261 1.48844 5.71111C1.16597 6.48962 1 7.32402 1 8.16667Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SearchBarIcon>
      </Container>
    );
  }
);

import {env} from '@src/config';
import {
  ErrorContainer,
  ErrorText,
  ErrorTriangle,
  ExtraText,
} from '@src/features/auth/components/AuthInput';
import Autocomplete from 'react-google-autocomplete';
import styled, {CSSProperties, useTheme} from 'styled-components';

export interface Place {
  address: string;
  zipCode?: string;
  city?: string;
  country: string;
  latitude: string;
  longitude: string;
  countryShortName: string;
}
type GSearchProps = {
  inputType: 'primary' | 'secondary';
  label?: string;
  error?: string;
  value?: Place;
  type: 'address' | 'city';
  handleNewPlace: (place: Place) => void;
  onResetFilter?: () => void;
  customStyle?: CSSProperties;
  customContainerStyle?: CSSProperties;
  placeHolder?: string;
};
export const AutocompleteContainer = styled.div`
  position: relative;
`;
export const Label = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #1a1818;
  padding-left: 5px;
`;
export const GSearch = ({
  handleNewPlace,
  label,
  value,
  error,
  customStyle,
  customContainerStyle,
  placeHolder,
  onResetFilter,
  type,
  ...props
}: GSearchProps) => {
  const {colors} = useTheme();
  const checkIfValueEmpty = (e: string) => {
    if (e === '' && onResetFilter) {
      onResetFilter();
    }
  };
  return (
    <AutocompleteContainer style={customContainerStyle}>
      {label ? (
        <Label className="text-m14">
          {label} {label !== '' ? <ExtraText>*</ExtraText> : <></>}
        </Label>
      ) : null}
      <Autocomplete
        className="google-autocomplete"
        defaultValue={
          value?.address
            ? `${value?.address} ${value?.city} ${value?.zipCode} ${value?.country}`
            : ''
        }
        language="de"
        options={
          type === 'address'
            ? {
                types: ['address'],
                fields: ['address_components', 'adr_address', 'geometry'],
              }
            : {}
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          checkIfValueEmpty(e.target.value)
        }
        style={
          customStyle
            ? customStyle
            : {
                width: '100%',
                height: '44px',
                padding: '0 20px',
                borderRadius: '10px',
                border: '1px solid rgba(47, 48, 113, 0.16)',
                background:
                  props.inputType === 'primary'
                    ? colors.neutral000
                    : colors.primary500,
                color:
                  props.inputType === 'secondary'
                    ? colors.neutral000
                    : colors.primary500,
                textAlign: props.inputType === 'primary' ? 'left' : 'center',
              }
        }
        // @ts-ignore
        textInputProps={{
          placeholderTextColor: colors.neutral100,
          opacity: 1,
        }}
        placeholder={placeHolder ? placeHolder : 'Ort'}
        apiKey={env.NEXT_PUBLIC_GOOGLE_API_KEY}
        onPlaceSelected={(place) => {
          if (!place) {
            return;
          }
          const finalPlace: Place = {
            address: '',
            zipCode: '',
            countryShortName: '',
            country: '',
            city: '',
            latitude: place.geometry
              ? place.geometry.location.lat().toString()
              : null,
            longitude: place.geometry
              ? place.geometry.location.lng().toString()
              : null,
          };
          if (place.address_components) {
            const streetNumberIndex = place.address_components.findIndex(
              (obj: {types: string | string[]}) =>
                obj.types.includes('street_number')
            );
            const routeIndex = place.address_components.findIndex(
              (obj: {types: string | string[]}) => obj.types.includes('route')
            );

            if (streetNumberIndex !== -1 && routeIndex !== -1) {
              const streetNumberObject = place.address_components.splice(
                streetNumberIndex,
                1
              )[0];
              place.address_components.splice(
                routeIndex + 1,
                0,
                streetNumberObject
              );
            }

            place.address_components.forEach(
              (component: {
                types: string | string[];
                long_name: string;
                short_name: string;
              }) => {
                if (component.types.includes('postal_code')) {
                  finalPlace.zipCode = component.long_name;
                }
                if (component.types.includes('locality')) {
                  finalPlace.city = component.long_name;
                }
                if (component.types.includes('country')) {
                  finalPlace.country = component.long_name;
                  finalPlace.countryShortName = component.short_name;
                }
                if (component.types.includes('route')) {
                  finalPlace.address += component.long_name + ' ';
                }
                if (component.types.includes('street_number')) {
                  finalPlace.address += component.long_name + ' ';
                }
                if (!component.types.includes('postal_code')) {
                  finalPlace.zipCode = '0';
                }
              }
            );
          }
          handleNewPlace(finalPlace);
        }}
      />
      <ErrorContainer style={{zIndex: 1}} show={error ? true : false}>
        {error ? (
          <>
            <ErrorText className="text-l12">{error}</ErrorText>
          </>
        ) : (
          ''
        )}
        <ErrorTriangle></ErrorTriangle>
      </ErrorContainer>
    </AutocompleteContainer>
  );
};

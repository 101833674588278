import {PaginationLogic} from '@src/commonlogic/PaginationLogic';
import {useDataLength} from '@src/commonhooks/useDataLenght';
import {SpinnerWrapped} from '@src/common/ui/SpinnerWrapped';
import {ErrorInformation} from '@src/common/ui/ErrorInformation';
import {NoDataInformation} from '@src/common/ui/NoDataInformation';
import InfiniteScroll from 'react-infinite-scroll-component';
import {ListSpinner} from '@src/common/ui/ListSpinner';
import {ClubCard} from '../components/ClubCard';
import {ClubApi} from '@src/api/club-api';
import styled from 'styled-components';
import {useInfiniteQuery, useQueryClient} from 'react-query';
import {Place} from '@src/common/inputs/GSearch';
import {useEffect} from 'react';

interface ClubListProps {
  search?: string;
  categoryId?: string;
  address?: Place;
  customRadius?: number;
  sort: 'CREATED_AT' | 'ADDRESS';
}

export const ClubList = ({
  search,
  address,
  categoryId,
  customRadius,
  sort,
}: ClubListProps) => {
  const {isLoading, isError, data, hasNextPage, fetchNextPage} =
    useInfiniteQuery(
      ['clubs', search, address, categoryId],
      ({pageParam = 1}) => {
        return ClubApi.getAll({
          search: search,
          sort_by: sort === 'CREATED_AT' ? 'createdAt_DESC' : 'distance_ASC',
          categoryId: categoryId,
          page: pageParam,
          latitude: address && address.latitude ? address.latitude : '',
          longitude: address && address.longitude ? address.longitude : '',
          searchArea:
            address?.latitude && address.longitude
              ? customRadius
                ? customRadius
                : 20
              : undefined,
        });
      },
      {
        getNextPageParam: PaginationLogic.handleGetNextParam,
      }
    );

  const qClient = useQueryClient();
  useEffect(() => {
    return () => {
      qClient.removeQueries(['clubs', search, address, categoryId]);
    };
  }, []);

  const dataLength = useDataLength(data);

  if (isLoading) {
    return (
      <InfoWrapper>
        <SpinnerWrapped />
      </InfoWrapper>
    );
  }

  if (isError) {
    return (
      <InfoWrapper>
        <ErrorInformation />
      </InfoWrapper>
    );
  }

  if (
    !data ||
    (data.pages.length > 0 && data.pages[0] && data.pages[0].data.length === 0)
  ) {
    return (
      <InfoWrapper>
        <NoDataInformation />
      </InfoWrapper>
    );
  }

  return (
    <ListContainer>
      <InfiniteScroll
        hasMore={hasNextPage || false}
        next={fetchNextPage}
        dataLength={dataLength}
        loader={<ListSpinner />}
      >
        {data.pages.map((it) => {
          return it.data.map((properItem) => (
            <ClubCard key={properItem.id} club={properItem} />
          ));
        })}
      </InfiniteScroll>
    </ListContainer>
  );
};

const ListContainer = styled.div``;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

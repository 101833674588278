import styled from 'styled-components';
import Image from 'next/image';
import {Club} from '@src/models/club';
import {categoryService} from '@src/features/auth/logic/category-service';
import {ApiImage} from '@src/common/ui/ApiImage';
import {useTranslation} from 'next-i18next';
import {useState, useEffect} from 'react';
import {handleGoogleMapRedirect} from '@src/utils/handleGoogleMapRedirect';
import {useRouter} from 'next/router';

const ClubCardWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 12px 0;
  align-items: center;
  padding: 5px;
  gap: 5px;
  background: ${(props) => props.theme.colors.neutral000};
  cursor: pointer;
  filter: drop-shadow(4px 4px 20px rgba(206, 207, 242, 0.5));
  border-radius: ${(props) => props.theme.borderRadii.s}px;
`;

const InformationWrapper = styled.div`
  flex-grow: 1;
  margin-left: 15px;
`;

const InformationText = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.primary500};
  margin: 2px 0;
`;

const CityInfo = styled.div`
  display: flex;
  gap: 5px;
  margin-top: auto;
  font-size: 0.8rem;
  position: absolute;
  right: 10px;
  bottom: 10px;
  span {
    color: ${(props) => props.theme.colors.primary500};
  }
`;

const SubInfoText = styled(InformationText)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 270px;

  @media screen and (max-width: 660px) {
    width: 100px;
  }
`;

interface ClubCardProps {
  club: Club;
}

export const ClubCard = ({club}: ClubCardProps) => {
  const {t} = useTranslation();
  const router = useRouter();
  const [token, setToken] = useState('');
  useEffect(() => {
    const tmpToken = localStorage.getItem('jwt');
    if (tmpToken) {
      setToken(tmpToken);
    }
  }, []);
  const logo = club.files.find((file) => file.type === 'COMPANY_AVATAR');
  return (
    <ClubCardWrapper
      onClick={() => {
        const url = token ? `/clubs/${club.id}` : `/clubs/${club.id}/guest`;
        router.push(url);
      }}
    >
      {logo ? (
        <ApiImage
          imageId={logo.id}
          width={100}
          height={100}
          style={{borderRadius: '10px', objectFit: 'cover', minWidth: '100px'}}
        />
      ) : (
        <Image
          alt="Club Image"
          src={'/images/mock-logo.png'}
          width={100}
          height={100}
          style={{borderRadius: '10px'}}
        />
      )}
      <InformationWrapper>
        <InformationText className="text-sb14 ">
          {club.companyName}
        </InformationText>
        <InformationText className="text-m14 ">
          {t('common:roles:PRESIDENT') + ': '}
          {club.presidentUserCompany
            ? [
                club.presidentUserCompany.name,
                club.presidentUserCompany.surname,
              ].join(' ')
            : '-'}
        </InformationText>
        <SubInfoText className="text-m14 ">
          {categoryService.getMostDetailedCategory(club.categories)}
        </SubInfoText>
      </InformationWrapper>
      <CityInfo
        onClick={(e) => {
          e.stopPropagation();
          handleGoogleMapRedirect(club?.address?.city as string);
        }}
      >
        <span className="text-m12">
          {club.address ? club.address.city : '-'}
        </span>

        <Image alt="Pin" src={'/icons/location.svg'} height={17} width={17} />
      </CityInfo>
    </ClubCardWrapper>
  );
};

import styled from 'styled-components';
import {CustomSearchBar} from '@src/common/filters/CustomSearchBar';
import {useTranslation} from 'next-i18next';
import {CustomSearchSelect} from '@src/common/filters/CustomSearchSelect';
import {useMemo, useState} from 'react';
import {devices} from '@src/common/screenSizes';
import {useQuery} from 'react-query';
import {useDebounce} from '@src/utils/useDebounce';
import {GSearch, Place} from '@src/common/inputs/GSearch';
import {CategoryApi} from '@src/api/category-api';
import {categoryService} from '@src/features/auth/logic/category-service';
import {ClubList} from '../components/ClubList';

const PickCLubWrapper = styled.div`
  width: 462px;
  margin: 15px auto;
  @media ${devices.sm} {
    width: 340px;
  }
`;
const ChosenType = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #1a1818;
  margin-top: 30px;
`;

export const ClubListScreen = ({}) => {
  const {t} = useTranslation();
  const [categoryId, setCategoryId] = useState('ALL');
  const [search, setSearch] = useState('');
  const [address, setAddress] = useState<Place>();

  const {data, isLoading} = useQuery(
    ['categories'],
    () => CategoryApi.getCategories({recursively: true}),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
  const categoriesData = useMemo(() => {
    if (!data) {
      return [];
    }
    return categoryService.mapCategories(data);
  }, [data]);

  const handleNewSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleNewSelectInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(e.target.value);
  };

  const debouncedSearch = useDebounce(search, 500);
  const debouncedAddress = useDebounce(address, 500);

  const handleNewAddress = (e: Place) => {
    setAddress(e);
  };
  const handleResetAddress = () => {
    setAddress({
      latitude: '',
      longitude: '',
      address: '',
      country: '',
      countryShortName: '',
    });
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <PickCLubWrapper>
      <CustomSearchBar
        onChange={handleNewSearchInput}
        value={search}
        placeHolder={t('common:search-club-by-name-president-categories')}
      />
      <CustomSearchSelect
        onChange={() => handleNewSelectInput}
        placeholder="asfasf"
        options={[
          {
            id: 'ALL',
            label: t(`user-profile:club-list:all-categories`),
            value: 'ALL',
          }, //@ts-ignore
        ].concat(categoriesData)}
      />

      <GSearch
        type="city"
        inputType="secondary"
        value={address}
        label={''}
        handleNewPlace={handleNewAddress}
        onResetFilter={handleResetAddress}
      ></GSearch>
      <ChosenType>{`${t(`user-profile:club-list:CLUBS`)}`}</ChosenType>
      <ClubList
        categoryId={categoryId}
        search={debouncedSearch}
        address={debouncedAddress as Place}
        sort={'CREATED_AT'}
      />
    </PickCLubWrapper>
  );
};

import {ForwardedRef, forwardRef, ComponentProps, useState} from 'react';
import styled, {useTheme} from 'styled-components';

const InputContainer = styled.div`
  grid-area: ${(props) =>
    props.style?.gridArea ? props.style?.gridArea : 'unset'};
`;

const Title = styled.p`
  margin: 0;
`;

const SelectCointainer = styled.select`
  border: 1px solid ${(props) => props.theme.colors.neutral300};
  border-radius: 0.7rem;
  height: 44px;
  margin-top: ${(props) => props.theme.spacing.s}px;
  margin-bottom: ${(props) => props.theme.spacing.s}px;
  padding: 5px 15px 5px 10px;
  font-size: 0.875rem;
  line-height: 1.3125;
  font-weight: 500;
  color: ${(props) => props.style?.color || props.theme.colors.neutral100};
  background: ${(props) => props.theme.colors.primary500};
  width: 100%;
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
  text-align: center;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.neutral100};
    opacity: 1;
  }
`;

interface CustomSelectProps extends ComponentProps<typeof SelectCointainer> {
  error: string;
  placeholder: string;
  options: {
    id: string;
    label: string;
    name?: string;
  }[];
}

export const CustomSearchSelect = forwardRef(
  (
    {options, title, isShadow, onChange, ...rest}: CustomSelectProps,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const [selectImg, setSelectImg] = useState('/icons/arrow-white.svg');
    const theme = useTheme();

    const handleToogleSelectImg = () => {
      if (selectImg === '/icons/arrow-white.svg') {
        setSelectImg('/icons/arrow-up-white.svg');
      } else {
        setSelectImg('/icons/arrow-white.svg');
      }
    };

    return (
      <InputContainer {...rest}>
        <Title className="text-m14">{title}</Title>
        <SelectCointainer
          {...rest}
          ref={ref}
          onBlur={() => setSelectImg('/icons/arrow-white.svg')}
          onClick={handleToogleSelectImg}
          onChange={onChange()}
          style={{
            backgroundImage: `url(${selectImg})`,
            boxShadow: `${isShadow ? theme.shadow.shadow1 : 'unset'}`,
            ...rest.style,
          }}
        >
          {options.map((opt) => (
            <option key={opt.id} value={opt.id} id={opt.id}>
              {opt.label || opt.name}
            </option>
          ))}
        </SelectCointainer>
      </InputContainer>
    );
  }
);
